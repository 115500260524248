<template>
    <div class="my-3">
        <div class="w-100">
            <h1 class="h3 text-center">
                Sublíneas en total
                <strong class="text-primary">
                    {{ itemsNumber }}
                </strong>
            </h1>
        </div>
        <div class="mt-3">
            <span>
                Las sublíneas de productos son una herramienta útil para
                clasificar y agrupar productos similares en una misma sublínea o
                grupo. Estas sublíneas permiten filtrar el inventario y asignar
                productos de manera más eficiente.
            </span>
        </div>

        <div class="d-flex justify-content-between flex-column flex-lg-row">
            <div class="my-3 d-flex justify-content-around">
                <vs-button relief @click="reloadItems">
                    Consultar 🔎
                </vs-button>
            </div>

            <div class="my-3 d-flex align-items-center justify-content-around">
                <vs-pagination
                    v-model="currentPage"
                    :length="pagesNumber"
                    @input="listItems()"
                />
                <tooltip-button
                    v-show="selected.length"
                    icon="🧹"
                    message="Limpiar selección"
                    @click="clearSelected"
                />
                <tooltip-button
                    v-show="
                        selected.length && $ability.hasScope('subline:Create')
                    "
                    icon="©"
                    message="Clonar sublínea"
                    @click="cloneSelected"
                />
                <tooltip-redirect
                    v-if="$ability.hasScope('subline:Create')"
                    to="/app/sublines/new"
                    message="Registrar sublínea"
                >
                </tooltip-redirect>
            </div>
        </div>

        <div v-show="showTable" class="w-100 mt-2">
            <b-table
                ref="selectableTable"
                :fields="$data.$fields"
                :items="itemsCurrent"
                :dark="activeDark"
                :per-page="itemsCurrent.length"
                select-mode="single"
                selectable
                responsive
                outlined
                no-border-collapse
                hover
                class="col-12"
                @row-selected="onRowSelected"
            >
                <template #cell(slug)="data">
                    <div class="d-flex align-items-center">
                        <router-link
                            :to="`/app/sublines/detail/${data.item.slug}`"
                            class="text-center font-weight-normal btn btn-primary p-1"
                        >
                            <div class="d-flex align-items-center">
                                <small class="ml-2">
                                    {{ data.item.active ? "🟢" : "⚪" }}
                                </small>
                                <span> &nbsp; {{ data.item.slug }} </span>
                            </div>
                        </router-link>
                    </div>
                </template>
                <template #cell(name)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.name }}
                    </small>
                </template>

                <template #cell(shortDescription)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.shortDescription | cut(30) }}
                    </small>
                </template>
                <template #cell(line)="data">
                    <router-link
                        :to="`/app/lines/detail/${data.item.line.slug}`"
                    >
                        <small class="text-center font-weight-normal">
                            {{ data.item.line.name }}
                        </small>
                    </router-link>
                </template>
                <template #cell(updatedAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.updatedAt | date }}
                    </small>
                </template>
            </b-table>
            <div v-if="!itemsCurrent.length" class="col-11 mx-auto mt-4">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import TooltipButton from "@/components/utils/TooltipButton.vue";
import TooltipRedirect from "@/components/utils/TooltipRedirect.vue";

export default {
    name: "SublinesIndex",
    components: { TooltipRedirect, TooltipButton },
    layout: "app",
    data: () => ({
        loaderInstance: null,
        itemsCurrent: [],
        selected: [],
        $fields: [
            { key: "slug", label: "Slug", sortable: true },
            { key: "name", label: "Nombre", sortable: true },
            {
                key: "shortDescription",
                label: "Descripción",
                sortable: false
            },
            { key: "line", label: "Línea", sortable: true },
            { key: "updatedAt", label: "📆 Actualización", sortable: true }
        ],
        showTable: false,
        currentPage: 1,
        pagesNumber: 0,
        itemsNumber: 0
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        this.listItems(true);
    },
    methods: {
        ...mapActions("sublines", ["listSublinesPaginated"]),
        async listItems(complete = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const { items, pagination } = await this.listSublinesPaginated({
                    page: this.currentPage,
                    complete
                });
                this.itemsCurrent = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        onRowSelected(items) {
            this.selected = items;
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
            this.selected = [];
        },
        cloneSelected() {
            window
                .open(`/app/sublines/clone/${this.selected[0].id}`, "_blank")
                .focus();
        }
    }
};
</script>
